import './styles/global.css';
import './App.css';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Home from './pages/Home/Home';
// import Chat from './pages/Chat/Chat';
import { ThemeProvider } from './context/ThemeContext';
// Carregamento preguiçoso das páginas
const Home = React.lazy(() => import('./pages/Home/Home'));
const Chat = React.lazy(() => import('./pages/Chat/Chat'));


function App() {
  return (
    <ThemeProvider>
      <Router>
      <Suspense fallback={<div>Carregando...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/nai" element={<Chat />} />
            {/* Adicione outras rotas conforme necessário */}
          </Routes>
      </Suspense>
      </Router>
    </ThemeProvider>

  );
}

export default App;